<template>
  <div>
    <div class="img_one">
      <img :src="info.top_image" alt="">
    </div>
    <div class="screenWidth">
      <currentLocation :sibItem="sibItem"></currentLocation>
      <div class="outer_one">
        <div ref="righst" class="outer_one_left">
          <div ref="titles" class="outer_one_left_1">About JIAHONG</div>
          <div ref="desc" class="outer_one_left_2" v-html="info.content"></div>
        </div>
        <div v-if="moreshow" class="moveview">
          <button @click="zhankai" class="moveview_box handshape">
            <div class="">
              Show all
            </div>
            <img class="zh" src="../assets/img/zk.png" alt="">
          </button>

        </div>
        <div class="outer_one_right" v-if="info != ''">
          <img :src="info.images_list[0]" class="outer_one_right_1" alt="">
          <div class="outer_one_right_2">
            <img :src="info.images_list[1]" class="outer_one_right_2_img" alt="">
          </div>
          <img :src="info.images_list[2]" class="outer_one_right_3" alt="">
        </div>
      </div>
    </div>
    <div class="outer_two screenWidth">
      <div class="outer_two_cont">
        <div @click="toproductDetails(item.id)" class="outer_two_cont_item" v-for="(item, index) in list" :key="index">
          <img :src="item.image" class="outer_two_cont_item_img" alt="">
          <div class="outer_two_cont_item_2">{{ item.title }}</div>
          <div class="outer_two_cont_item_3">{{ item.descript }}</div>
          <div class="outer_two_cont_item_4">{{ item.createtime }}</div>
        </div>
      </div>
      <!-- <div class="outer_two_three">
        <div>MORE</div>
      </div> -->
    </div>
    <div class="outer_three">
      <img src="@/assets/img/gywm_1.png" class="outer_three_bj" alt="">
      <div class="outer_three_cont">
        <div class="outer_tip_1">Company honor</div>
        <div class="outer_tip_2">JIAHONG concentrates on every gram of HPMC</div>
        <div class="outer_three_cont_one screenWidth">
          <vue-seamless-scroll :data="honor_list" :class-option="options" class="seamless-warp">
            <ul>
              <li v-for="item in honor_list" :key="item.index">
                <img :src="item.image" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <div class="outer_four screenWidth">
      <div class="outer_tip_1">Contact Us and Get Free Samples</div>
      <div class="outer_four_cont">
        <div class="outer_four_cont_one">
          <input type="text" placeholder="Name" v-model="name" @blur="nameBlur" @input="nameBlur"
            :class="nameTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (nameTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="outer_four_cont_one">
          <input type="text" placeholder="*Email" v-model="email" @blur="emailBlur" @input="emailBlur"
            :class="emailTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (emailTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="outer_four_cont_one">
          <input type="text" placeholder="*Tel/Whatsapp" v-model="mobile" @blur="mobileBlur" @input="mobileBlur"
            :class="mobileTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (mobileTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="outer_four_cont_one textarea">
          <textarea placeholder="*Message" required="" v-model="message" @blur="messageBlur" @input="messageBlur"
            :class="messageTip == true ? 'bjRed' : ''"></textarea>
          <div class="errormessage" :style="'display: ' + (messageTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="outer_four_cont_one submitGroup">
          <div class="formsubmit" @click="submit">Submit</div>
        </div>
      </div>
    </div>
    <div class="outer_five">
      <div class="outer_five_item">
        <img src="@/assets/img/gywm_2.png" class="outer_five_item_1" alt="">
        <div class="outer_five_item_2">INTEGRITY</div>
      </div>
      <div class="outer_five_item">
        <img src="@/assets/img/gywm_3.png" class="outer_five_item_1" alt="">
        <div class="outer_five_item_2">QUALITY</div>
      </div>
      <div class="outer_five_item">
        <img src="@/assets/img/gywm_4.png" class="outer_five_item_1" alt="">
        <div class="outer_five_item_2">SERVICE</div>
      </div>
      <div class="outer_five_item">
        <img src="@/assets/img/gywm_5.png" class="outer_five_item_1" alt="">
        <div class="outer_five_item_2">INNOVATE</div>
      </div>
    </div>


    <!-- 富文本弹窗 -->
    <el-dialog :visible.sync="fwbshow" title="About JIAHONG" center>
      <div class="more_boxs">
        <div class="Companyprofile" v-html="info.content"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们
 * @author: 刘嘉鑫 
 * @version  V1.0 
 * @datecreate: 2024-07-19 16:24
 */
export default {
  data() {
    return {
      sibItem: [
        {
          title: "Home",
          path: "/",
        },
        {
          title: "About Us",
          path: "",
        },
      ],
      info: "",
      list: "",
      honor_list: [],

      name: "",
      nameTip: false,
      email: "",
      emailTip: false,
      mobile: "",
      mobileTip: false,
      message: "",
      messageTip: false,
      moreshow: false,//是否展示更多按钮
      fwbshow: false
    };
  },
  computed: {
    options() {
      return {
        // 滚动速度 , 数值越大滚动越快
        step: 0.8,
        // 滚动的数据条数
        limitMoveNum: 2,
        // 鼠标点击停止滚动, 默认true
        hoverStop: true,
        // 按住鼠标停止滚动, 默认true
        openTouch: true,
        // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
        direction: 2,
        // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
        // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
        // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
        singleHeight: 32,
        // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
        singleWidth: 0,
        // 单步运动停止的时间 (默认值1000ms)
        waitTime: 0,
        // 开启数据实时监控刷新dom
        openWatch: true,
      };
    },
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    zhankai() {
      this.fwbshow = true
    },
    /**
     * 详情
     * 刘嘉鑫
     * 2024-7-19
     */
    getInfo() {
      this.$request({
        url: "/Index/aboutUs",
      }).then((res) => {
        console.log("关于我们", res);
        this.info = res.data.about_info;
        this.list = res.data.about_list;
        setTimeout(() => {
          const fuheight = this.$refs.desc ? this.$refs.desc.clientHeight : 0; //根据元素的clientheight来判断
          const tiheight = this.$refs.titles ? this.$refs.titles.clientHeight : 0; //根据元素的clientheight来判断
          const riheight = this.$refs.righst ? this.$refs.righst.clientHeight : 0; //根据元素的clientheight来判断
          console.log(fuheight + tiheight, riheight, '222');
          if (fuheight + tiheight > riheight) {
            this.moreshow = true
          } else {
            this.moreshow = false
          }
        }, 100)

      });
      this.$request({
        url: "/Index/index",
      }).then((res) => {
        console.log("首页", res);
        this.honor_list = res.data.honor_list;
      });
    },

    /**
     * 提交反馈
     * 刘嘉鑫
     * 2024-7-20
     */
    submit() {
      if (this.name == '') {
        this.nameBlur()
        return
      } else if (this.email == '') {
        this.emailBlur()
        return
      } else if (this.mobile == '') {
        this.mobileBlur()
        return
      } else if (this.message == '') {
        this.messageBlur()
        return
      }
      this.$request({
        url: "/Index/feedBack",
        data: {
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          message: this.message,
        }
      }).then((res) => {
        console.log("提交成功", res);
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: 'success',
            offset: 100
          });
          this.name = ""
          this.email = ""
          this.mobile = ""
          this.message = ""
        }
      }).catch(err => {
        console.log(err, '123');
        this.$message({
          message: err.msg,
          type: 'warning',
          offset: 100
        });
      })
    },

    /**
     * 名字失去焦点
     * 刘嘉鑫
     * 2024-7-20
     */
    nameBlur() {
      if (this.name == '') {
        this.nameTip = true
      } else {
        this.nameTip = false
      }
    },
    emailBlur() {
      if (this.email == '') {
        this.emailTip = true
      } else {
        this.emailTip = false
      }
    },
    mobileBlur() {
      if (this.mobile == '') {
        this.mobileTip = true
      } else {
        this.mobileTip = false
      }
    },
    messageBlur() {
      if (this.message == '') {
        this.messageTip = true
      } else {
        this.messageTip = false
      }
    },
  },
};
</script>
<style scoped>
/deep/ .el-dialog {
  width: 35vw !important;
  max-height: 60vh !important;
  overflow: hidden;
  border-radius: 5px !important;
}

.el-dialog__body {
  box-sizing: border-box;
  max-height: 60vh !important;
  padding: 10px 30px 120px 30px !important;
}

.el-dialog__title {
  font-size: 20px;
}

.el-dialog__headerbtn .el-dialog__close:hover {
  color: #BA0003;
}
</style>
<style lang='less' scoped>
// 手机
@media (max-width: 768px) {
  .outer_tip_1 {
    font-size: 0.5em !important;
  }

  .outer_tip_2 {
    font-size: 0.28em !important;
  }

  .outer_one_left {
    height: 100% !important;
  }


  .img_one {
    height: 1200px !important;
  }

  .outer_one {
    flex-direction: column !important;
    margin-top: 10px !important;

    >div {
      width: 100% !important;
      margin-right: 0 !important;
    }

    .outer_one_left_1 {
      font-size: 90px !important;
      text-align: center !important;
      margin-top: 100px !important;
    }

    .outer_one_left_2 {
      font-size: 60px !important;
      line-height: 30px !important;
    }

    .outer_one_right {
      margin-top: 40px;
    }

    .outer_one_right_1 {
      height: 8em !important;
      margin-bottom: 0.8em !important;
    }

    .outer_one_right_2 {
      height: 11em !important;
    }

    .outer_one_right_3 {
      height: 10em !important;
      margin-top: -130px !important;
    }
  }

  .outer_two {
    .outer_two_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 40px !important;

      .outer_two_cont_item_img {
        height: 8em !important;
      }

      .outer_two_cont_item_2 {
        font-size: 80px !important;
      }

      .outer_two_cont_item_3 {
        font-size: 60px !important;
      }

      .outer_two_cont_item_4 {
        font-size: 0.6em !important;
      }
    }

    .outer_two_three div {
      font-size: 0.4em !important;
    }
  }

  .outer_three {
    .seamless-warp {
      height: 4em !important;

      img {
        width: 6em !important;
        height: 4em !important;
      }
    }
  }

  .outer_four {
    .outer_four_cont {
      .outer_four_cont_one {
        width: 100% !important;
        padding: 0 !important;
        margin-bottom: 30px !important;

        input,
        textarea {
          font-size: 60px !important;
          padding: 55px !important;
          box-sizing: border-box;
        }

        .errormessage {
          font-size: 60px !important;
        }
      }

      .textarea textarea {
        width: 96% !important;
      }

      .submitGroup {
        flex-direction: row !important;

        .formsubmit {
          font-size: 60px !important;
          padding: 40px 100px !important;
          width: 20% !important;
        }
      }
    }
  }


  .outer_five {
    flex-wrap: wrap !important;

    .outer_five_item {
      width: 50% !important;

      .outer_five_item_2 {
        padding: 60px 0 !important;
        font-size: 60px !important;
      }
    }
  }

}

// 平板
@media (min-width: 768px) and (max-width: 1024px) {

  .outer_tip_1 {
    font-size: 72px !important;
  }

  .outer_tip_2 {
    font-size: 42px !important;
  }

  .img_one {
    height: 11em !important;
  }

  .outer_one_left {
    height: 100% !important;
  }

  .outer_one {
    flex-direction: column !important;

    >div {
      width: 100% !important;
      margin-right: 0 !important;
    }

    .outer_one_left_1 {
      font-size: 1.2em !important;
    }

    .outer_one_left_2 {
      font-size: 0.8em !important;
      line-height: 1.5em !important;
    }

    .outer_one_right {
      margin-top: 30px;
    }

    .outer_one_right_1 {
      height: 9em !important;
      margin-bottom: 0.4em !important;
    }

    .outer_one_right_2 {
      height: 12.8em !important;
    }

    .outer_one_right_3 {
      height: 12em !important;
      margin-top: -130px !important;
    }
  }

  .outer_two {
    .outer_two_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 40px !important;

      .outer_two_cont_item_img {
        height: 8em !important;
      }

      .outer_two_cont_item_2 {
        font-size: 0.9em !important;
      }

      .outer_two_cont_item_3 {
        font-size: 0.7em !important;
      }

      .outer_two_cont_item_4 {
        font-size: 0.7em !important;
      }
    }


    .outer_two_three div {
      font-size: 0.9em !important;
    }
  }

  .outer_four {
    .outer_four_cont {
      .outer_four_cont_one {
        width: 100% !important;
        padding: 0 !important;
        margin-bottom: 30px !important;

        input,
        textarea {
          font-size: 60px !important;
          padding: 30px !important;
        }

        .errormessage {
          font-size: 50px !important;
        }
      }

      .textarea textarea {
        width: 96% !important;
      }

      .submitGroup {
        flex-direction: row !important;

        .formsubmit {
          font-size: 60px !important;
          padding: 40px 100px !important;
          width: 20% !important;
        }
      }
    }
  }

  .outer_five {
    .outer_five_item {
      .outer_five_item_2 {
        padding: 40px 0 !important;
        font-size: 40px !important;
      }
    }
  }
}

.more_boxs {
  overflow-y: auto;
  max-height: 50vh;

  .Companyprofile {
    word-break: break-word !important;
  }
}

.outer_tip_1 {
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  color: #222222;
  margin-bottom: 18px;
}

.outer_tip_2 {
  font-weight: 400;
  font-size: 16px;
  color: #444444;
  text-align: center;
  margin-bottom: 60px;
}



.img_one {
  width: 100%;
  height: 502px;

  img {
    width: 100%;
    height: 100%;
  }
}

.outer_one {
  display: flex;
  align-items: flex-start;
  margin-bottom: 100px;
  margin-top: 100px;
  position: relative;


  .moveview {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: -7px;
    right: 47.5%;

    .moveview_box {
      background: #fff !important;
      padding: 5px 10px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div {
        font-size: 1em;
        color: #BA0003;

      }

      .zh {
        width: 22px;
        height: 10px;
      }
    }
  }

  .outer_one_left {
    width: 54%;
    margin-right: 60px;
    height: 565px;
    overflow: hidden;


    .outer_one_left_1 {
      font-weight: bold;
      font-size: 38px;
      color: #222222;
      padding-bottom: 50px;
    }

    .outer_one_left_2 {
      font-weight: 400;
      font-size: 20px !important;
      color: #444444;

      /deep/ p {
        line-height: 1.8em !important;
      }

    }
  }

  .outer_one_right {
    width: 45%;
    display: flex;
    flex-wrap: wrap;

    .outer_one_right_1 {
      width: 50%;
      height: 220px;
      margin-bottom: 27px;
    }

    .outer_one_right_2 {
      width: 50%;
      height: 326px;
      background: #ffffff;
      z-index: 10;
      display: flex;
      justify-content: flex-end;

      .outer_one_right_2_img {
        width: 87%;
        height: 93%;
      }
    }

    .outer_one_right_3 {
      width: 100%;
      height: 326px;
      margin-top: -66px;
    }
  }
}

.outer_two {
  margin-bottom: 80px;

  .outer_two_cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    align-items: flex-start;

    .outer_two_cont_item {

      .outer_two_cont_item_img {
        width: 100%;
        height: 260px;
        margin-bottom: 10px;
      }

      .outer_two_cont_item_2 {
        font-weight: bold;
        font-size: 18px;
        color: #222222;
        margin-bottom: 10px;
      }

      .outer_two_cont_item_3 {
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        margin-bottom: 28px;
      }

      .outer_two_cont_item_4 {
        font-weight: 400;
        font-size: 16px;
        color: #999999;
      }
    }

    .outer_two_cont_item:last-child {
      margin-right: 0 !important;
    }
  }

  .outer_two_three {
    display: flex;
    justify-content: center;

    div {
      background: #BA0003;
      border-radius: 4px;
      padding: 16px 22px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
  }


  .outer_three {
    .seamless-warp {
      height: 8em !important;

      img {
        width: 10em !important;
        height: 8em !important;
      }
    }
  }
}

.outer_three {
  position: relative;

  .outer_three_bj {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .outer_three_cont {
    padding: 100px 0;
    position: relative;

    .outer_three_cont_one {
      .seamless-warp {
        height: 220px;
        overflow: hidden;

        ul {
          display: flex;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;

          li {
            list-style: none;

            img {
              width: 324px;
              height: 220px;
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}

.outer_four {
  margin-top: 100px;
  margin-bottom: 100px;

  .outer_four_cont {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .outer_four_cont_one {
      width: 48.3%;
      margin-bottom: 16px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      input,
      textarea {
        width: 96%;
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        box-shadow: none;
        border-radius: 4px;
        outline: 0;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) inset;
      }

      .bjRed {
        background: #ffece8 !important;
      }

      .errormessage {
        font-weight: 400;
        font-size: 14px;
        color: #BA0003;
        clear: both;
        display: none;
        margin-top: 5px;
      }
    }

    .textarea {
      width: 100% !important;

      textarea {
        width: 98%;
      }
    }

    .submitGroup {
      width: 100% !important;
      display: flex;
      justify-content: center;

      .formsubmit {
        margin: 0 auto;
        width: 160px;
        border: 1px solid #BA0003;
        border-radius: 4px;
        background: #BA0003;
        color: #fff;
        font-size: 16px;
        padding: 8px 0px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

.outer_five {
  display: flex;
  align-items: center;

  .outer_five_item {
    position: relative;

    width: 25%;

    .outer_five_item_1 {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .outer_five_item_2 {
      padding: 40px 0;
      font-weight: bold;
      font-size: 18px;
      color: #222222;
      text-align: center;
      position: relative;
    }
  }
}
</style>